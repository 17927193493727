import { useEffect, useRef, useState } from 'react'

import { Flex, Heading, useToasts } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import {
  ApplicationForms,
  ApplicationTimeline,
  BackToApplication,
} from '../../components/Application'
import { DesktopHeader } from '../../components/Application/ApplicationHeader/DesktopHeader'
import ApplicationStateTag from '../../components/Application/ApplicationStateTag/ApplicationStateTag'
import { Loading } from '../../components/Loading'
import { NavBars } from '../../components/NavBars'
import { ApplicationState, RawApplicationAggregationResponse, RawApplicationResponse, useGetApplication } from '../../hooks'
import { GenericError, up } from '../../utils'
import { useQueryClient } from '@tanstack/react-query'
import { SubmitApprovalDialog } from './SubmitApprovalDialog'
import { SubmitConfirmation } from './SubmitConfirmation'
import { useSubmitApplication } from '../../hooks/applications/useSubmitApplication'
import { DropdownMenu } from './DropdownMenu'

type CreateApplicationPageProps = {
  new?: boolean
}

export function CreateApplicationPage(props: CreateApplicationPageProps) {
  const toast = useToasts();
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const currentApplicationId = searchParams.get('applicationId') || ''

  const { isLoadingApplication, application } = useGetApplication({
    id: currentApplicationId,
  })

  const viewApplicationPageRef = useRef<HTMLDivElement>(null);

  const { isSubmittingApplication, submitApplication } = useSubmitApplication({
    id: currentApplicationId,
  })

  const [showSubmitApprovalDialog, setShowSubmitApprovalDialog] = useState(false)
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false)

  const [currentStep, setCurrentStep] = useState(1)
  const selectedTab = currentStep

  useEffect(() => {
    if (
      application &&
      application.attributes?.applicationState !== ApplicationState.DRAFT &&
      currentStep < 6
    ) {
      navigate(`/applications/${application.id}`, { replace: true })
      toast.warning(
        'This application has already been submitted. The page has been refreshed to reflect the current status.',
      )
    }
  }, [application, navigate, toast])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isLoadingApplication && viewApplicationPageRef.current) {
        viewApplicationPageRef.current.focus();
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [isLoadingApplication]);

  const handleSubmitApplicationTrigger = async () => {

    // TODO: implement intake update flow
    // https://applyboard.atlassian.net/browse/SEM-3034

    // await queryClient.invalidateQueries({
    //   queryKey: ['program-intake', intake?.id],
    // })
    // const updatedIntake = queryClient.getQueryData([
    //   'program-intake',
    //   intake?.id,
    // ]) as GetProgramIntakeResponse

    // if (!updatedIntake?.data?.attributes?.isAvailable) {
    //   setOpenIntakeClosedDialog(true)
    // } else {
    //   setShowSubmitApprovalDialog(true)
    // }

    setShowSubmitApprovalDialog(true)
  }

  const handleSubmitApplication = (
    successCallback: (response: RawApplicationResponse) => void,
    errorCallback: (err: GenericError) => void,
  ) => {
    submitApplication(undefined, {
      onSuccess: response => {
        successCallback(response)
      },
      onError: err => {
        if (err instanceof Error) {
          errorCallback(err)
        }
      },
    })
  }

  const handleSubmitConfirmation = async () => {
    handleSubmitApplication(
      (response: RawApplicationResponse) => {
        queryClient.setQueryData(
          ['applications', response.data.id],
          (oldData: RawApplicationAggregationResponse): RawApplicationAggregationResponse => {
            return {
              data: {
                ...oldData.data,
                attributes: {
                  ...oldData.data.attributes,
                  application: response.data,
                },
              },
            }
          },
        )

        setShowSubmitApprovalDialog(false)
        setShowSubmitConfirmation(true)
      },
      (err: GenericError) => {
        setShowSubmitApprovalDialog(false)
        setShowSubmitConfirmation(false)
        toast.negative(err)
      },
    )
  }

  if (currentApplicationId && isLoadingApplication) {
    return (
      <NavBars>
        <Loading />
      </NavBars>
    )
  }

  if (!application) {
    return <Navigate to="/not-found" />
  }

  return (
    <ApplicationWrapper tabIndex={-1} ref={viewApplicationPageRef}>
      <NavBars largePage>
        <BackToApplication label="New Application" />
        <Flex direction="column" gap={10}>
          <Flex wrap={false} align="center" justify="between" gap={4}>
            <Flex
              hideBelow={selectedTab === 7 ? 'sm' : undefined}
              align={{ xs: 'start', md: 'center' }}
              direction={{ xs: 'column', md: 'row' }}
              gap={4}
              py={4}
            >
              <Heading level={1} variant="headlineL">
                New Application
              </Heading>
              {application ? (
                <ApplicationStateTag state={application.attributes?.applicationState} />
              ) : null}
            </Flex>
            <DropdownMenu
              application={application}
              // Will be uncommented/tested in the future on SEM-3203
              // onEditProgram={() => {
              //   setCurrentStep(1)
              // }}
            />
          </Flex>
          <Flex gap={{ xs: 6, md: 12 }} direction={{ xs: 'column', md: 'row' }}>
            <Flex direction="column" gap={4} basis={{ xs: '100%', md: 278 }}>
              <DesktopHeader application={application} showProgramName={true} />
              <Flex hideBelow="md">
                <ApplicationTimeline
                  selectedTab={selectedTab}
                  setSelectedTab={tab => setCurrentStep(tab)}
                  application={application}
                />
              </Flex>
            </Flex>
            <FormWrapper>
              <Flex hideAbove="md" align="center" justify="center">
                <ApplicationTimeline
                  selectedTab={selectedTab}
                  setSelectedTab={tab => setCurrentStep(tab)}
                  application={application}
                />
              </Flex>
              <ApplicationForms
                selectedTab={selectedTab}
                application={application}
                onSuccess={response => {
                  if (response && selectedTab <= 6) {
                    queryClient.invalidateQueries({
                      queryKey: ['applications', response.data.id],
                    })
                    setCurrentStep(currentStep + 1)
                  }
                }}
                submitApplication={handleSubmitApplicationTrigger}
              />

              <SubmitApprovalDialog
                showSubmitApprovalDialog={showSubmitApprovalDialog}
                setShowSubmitApprovalDialog={setShowSubmitApprovalDialog}
                onSubmitConfirmation={handleSubmitConfirmation}
                isLoading={isSubmittingApplication}
              />
              <SubmitConfirmation
                showSubmitConfirmation={showSubmitConfirmation}
                setShowSubmitConfirmation={setShowSubmitConfirmation}
                onClose={() => {
                  navigate(`/applications/${application.id}`)
                }}
              />
            </FormWrapper>
          </Flex>
        </Flex>
      </NavBars>
    </ApplicationWrapper>
  )
}

const FormWrapper = styled.div({
  width: '100%',
  [up('md')]: {
    width: 'calc(100% - 326px)',
  },
})

const ApplicationWrapper = styled.div({
  outline: 'none',
})
